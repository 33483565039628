<template>
	<div class="media-detail">
		<mall-banner 
			page-position="图文制作/拉页" 
			router-name="前往墙面定制" 
			router-path="/metope">
            <!-- 搜索框 -->
            <template #search>
                <search-component
                    :search-value="search"
                    :selected-value="selectedType"
                    :select-options="testArray1"
                    @handleSearch="handleSearch">
                </search-component>
            </template>
		</mall-banner>
		<div class="container">
			<!-- 壁画 -->
			<div class="media-detail-info">
				<div class="title">简约欧式风情植物客厅卧室装饰画</div>
				<div class="desc" style="margin: 10px 0;">
					<p>挑选完模板后按“立即使用”按钮进行下一步打印材质工艺挑选哦！</p>
				</div>
				<div class="items">
					<div class="left-item">
						<div class="first"></div>
						<div class="last"></div>
					</div>
					<div class="right-item">
						<div class="button" @click="handleAddOrder">立 即 使 用</div>
						<div class="option" style="margin-top: 15px;">
							<p>
								模板价格：
								<span class="blue">20</span>元
							</p>
							<p>
								已有
								<span class="blue">87</span>人下单
							</p>
						</div>

						<div class="option">
							<p>编号：</p>
							<p>ZYJY000001</p>
						</div>

						<div class="option">
							<p>模板尺寸：</p>
							<p>1300mm x 2000mm</p>
						</div>

						<div class="option">
							<p>模板分辨率：</p>
							<p>300dpi</p>
						</div>

						<div class="option">
							<p>发布日期：</p>
							<p>2020/4/22</p>
						</div>

						<div class="statement">版权所有：吉享控件已获取作者授权，有权就作品进行销售</div>
						<div class="release-info">
							<div>发布者信息</div>
							<div class="avatar-info">
								<div class="avatar"></div>
								<div class="desc">
									<p>喵kiity</p>
									<p>画做错了什么？为什么要插他~</p>
								</div>
							</div>
						</div>
						<div class="release-images">
							<p>ta的相关发布</p>
							<div class="images">
								<div class="example-image"></div>
								<div class="example-image"></div>
								<div class="example-image"></div>
								<div class="example-image"></div>
								<div class="example-image"></div>
								<div class="example-image"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState, mapMutations, mapActions } from "vuex";

	export default {
		data() {
			return {
				search: '',
				selectedType: '0',
				selectedSpec: [],
				testArray1: [
					{
						value: '1',
						label: "拉页"
					},
					{
						value: '2',
						label: "相册"
					},
					{
						value: '3',
						label: "台历"
					},
					{
						value: '4',
						label: "名片"
					},
					{
						value: '5',
						label: "宣传单"
					},
					{
						value: '6',
						label: "明信片"
					},
				],
			};
		},
		components: {
			searchComponent: () => import("@/components/imageMall/searchComponent"),
			mallBanner: () => import("@/components/makingMall/mallBanner"),
		},
		computed: {
			//引入vuex中的数据
			...mapState({
				is_login: state => state.user.is_login,
				user_id: state => state.user.user_id,
				metopeDetail: state => state.metope.metopeDetail,
				metopeImages: state => state.metope.metopeImages,
				metopeOwner: state => state.metope.metopeOwner,
				specList: state => state.metope.specList,
				priceList: state => state.metope.priceList,
				filterType: state => state.metope.filterType
			})
		},
		methods: {
			//方法
			...mapMutations([
				'setMetopeFilters',
				'setMetopeDetail'
			]),
			...mapActions([
				'getMetopeFilters',
				'getMetopeDetail',
				'handleAddMetopeOrder'
			]),
			handleAddOrder(){
				let count = 0;
				// 判断是否登录
				if(!this.is_login){
					this.$message({
						showClose: true,
						message: '请先登录',
						type: 'warning'
					})
					return
				}
				// // 判断是否选择规格
				// if(this.selectedSpec.length != this.specList.length){
				// 	this.$message({
				// 		showClose: true,
				// 		message: '请选择规格',
				// 		type: 'warning'
				// 	})
				// 	return
				// }
				// // 判断是否输入尺寸
				// if(this.need_size == 1 && (!this.width || !this.height)) {
				// 	this.$message({
				// 		showClose: true,
				// 		message: '请输入尺寸',
				// 		type: 'warning'
				// 	})
				// 	return
				// }
				
				// count = this.need_size == 0? 0: (this.width + '*' + this.height);
				// 请求
				this.handleAddMetopeOrder({
					userID: this.user_id,
					id: 112787920409342124,
					count,
					quantity: 1,
					spec1: '细沙墙纸',
					spec2: '其他'
				}).then(res => {
					this.$message({
						showClose: true,
						message: res.msg,
						type: res.type? 'success': 'error'
					})
					res.type && this.$router.push({
						name: 'metopePay',
						query: {
							id: res.orderID
						}
					})
				}).catch(err => {})
			}
		}
	};
</script>

<style scoped>
.blue {
	color: rgb(17, 66, 168);
	font-size: 18px;
}
.container {
	padding-top: 20px;
}
.media-detail {
	--color: #1f3eaa;
	background-color: rgb(250, 250, 250);
}
.media-detail-info {
	border-radius: 0 !important;
	margin-top: 20px;
	margin-bottom: 50px;
	padding: 20px;
	border-radius: 10px;
	background-color: #fff;
}
.desc {
	color: rgb(187, 187, 187);
	font-size: 1px;
}
.media-detail-info > .title {
	font-size: 20px;
}
.media-detail-info > .items {
	display: flex;
}
.media-detail-info > .items > .left-item {
	width: 70%;
	height: 100%;
}
.media-detail-info > .items > .left-item > .first {
	width: 100%;
	height: 1800px;
	background-image: url("../assets/images/wall/template-example.jpg");
}

.media-detail-info > .items > .right-item {
	width: 30%;
	height: 100%;
	padding: 0 35px;
}
.media-detail-info > .items > .right-item > .option {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
}
.media-detail-info > .items > .right-item > .option_select > .select {
	margin: 10px 0;
	width: 200px;
	height: 25px;
	color: #000;
	text-align: center;
	line-height: 25px;
	border-radius: 15px;
}
.media-detail-info > .items > .right-item > .option_select > .active {
	background-color: rgb(17, 66, 168);
	color: #fff;
}
.media-detail-info > .items > .right-item > .button {
	width: 100%;
	height: 40px;
	color: #fff;
	text-align: center;
	line-height: 40px;
	background-color: rgb(17, 66, 168);
	border-radius: 10px;
}
.media-detail-info > .items > .right-item > .statement {
	margin-top: 10px;
	color: rgb(187, 187, 187);
	font-size: 1px;
}
.release-info {
	margin-top: 50px;
}
.avatar-info {
	display: flex;
	align-items: center;
	height: 100px;
}
.avatar-info > .avatar {
	margin-right: 15px;
	width: 60px;
	height: 60px;
	background-color: rgb(17, 66, 168);
	border-radius: 9999px;
}
.avatar-info > .desc :last-child {
	color: rgb(187, 187, 187);
}
.example-image {
	width: 80px;
	height: 100px;
	background-image: url("../assets/images/wall/WX20200416-112206@2x.jpg");
}
.images {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-row-gap: 5px;
	grid-template-rows: 1fr 1fr;
}
</style>
